import { navigate } from "gatsby";
import React from "react";
import { Button } from "react-bootstrap";
import * as Realm from "realm-web";
import { getUrlParam } from "../utils";

export const APP_ID = process.env.GATSBY_REALM_APP_ID;
// for now, only allow facilitator app in dev environment
export const FACILITATOR_APP_ID = process.env.GATSBY_REALM_FACILITATOR_APP_ID;
const RealmAppContext = React.createContext(null);

export const RealmAppProvider = ({ appId, children }) => {
  const [app, setApp] = React.useState(new Realm.App(appId));
  const db = app.currentUser?.mongoClient("mongodb-atlas").db("test_db");

  React.useEffect(() => {
    setApp(new Realm.App(appId));
  }, [appId]);

  const [appCurrentUser, setAppCurrentUser] = React.useState(app.currentUser);
  const [appUsers, setAppUsers] = React.useState(app.allUsers);
  const [appCustomData, setCustomData] = React.useState(
    app.currentUser?.customData
  );

  async function Register(email, password): Promise<void> {
    await app.emailPasswordAuth.registerUser(email, password);
  }

  async function LogIn(email, password): Promise<void> {
    const credentials = Realm.Credentials.emailPassword(email, password);
    await app.logIn(credentials);
    setAppCurrentUser(app.currentUser);
    setAppUsers(app.allUsers);
  }

  async function LogInAnonymous(): Promise<void> {
    await app.logIn(Realm.Credentials.anonymous());
    setAppCurrentUser(app.currentUser);
    setAppUsers(app.allUsers);
  }

  async function LogOut(): Promise<void> {
    console.log("trying to log out");
    // Log out the currently active user
    try {
      if (app.currentUser !== null) {
        const loggingOutUser = app.currentUser;
        await loggingOutUser.logOut();
        setAppCurrentUser(app.currentUser);
        setAppUsers(app.allUsers);
      }
    } catch (err) {
      if (err.errorCode == "InvalidSession") {
        console.log("got invalid session...");
        console.log("current_user id is " + app.currentUser?.id);
        console.log("isLoggedIn is " + app.currentUser?.isLoggedIn);
        console.log("state is " + app.currentUser?.state);
        console.log("attempting to remove " + app.currentUser?.id);
        await app.removeUser(app.currentUser);
        console.log("currentUser is now " + app.currentUser?.id);
        // throw err
      } else {
        throw err;
      }
    }
    // If another user was logged in too, they're now the current user.
    // Otherwise, app.currentUser is null.
  }

  async function appRefreshCustomData(): Promise<void> {
    await app.currentUser?.refreshCustomData();
    // sometimes app.currentUser changes when custom data is refreshed
    setAppCurrentUser(app.currentUser);
    setCustomData(app.currentUser?.customData);
  }

  async function setMongoUserAttribute(data, refreshUserData = true) {
    const users =
      appId == APP_ID ? db.collection("User") : db.collection("Facilitator");
    const userType = appId == APP_ID ? "member" : "facilitator";

    console.log(
      `setting data "${JSON.stringify(data)}" for ${userType} "${
        app.currentUser?.customData._id
      }"`
    );
    const result = await users.updateOne(
      { _id: app.currentUser?.customData._id },
      { $set: data },
      { upsert: true }
    );

    console.log("set new data result");
    console.log(result);
    if (refreshUserData) {
      await appRefreshCustomData();
    }
    return result;
  }

  async function maybeSetMongoUserBoolean(bool_var, refreshUserData = true) {
    let user_attr_set = {};
    user_attr_set[bool_var] = true;
    if (!Boolean(app.currentUser?.customData[bool_var])) {
      console.log(`user ${bool_var} currently false. setting to true.`);
      await setMongoUserAttribute(user_attr_set);
      return true;
    } else {
      return false;
    }
  }

  async function fetchCircle() {
    let memberCircleCode = app.currentUser?.customData.circle;
    let facilitatorCircleCode = app.currentUser?.customData?.owned_circle_code;
    const circleCode = memberCircleCode
      ? memberCircleCode
      : facilitatorCircleCode;

    if (circleCode) {
      const foundCircle = await db
        .collection("Circle")
        .findOne({ code: circleCode });
      return foundCircle;
    } else {
      return null;
    }
  }

  async function fetchMentorMentee(
    fieldsToFetch: object
  ): Promise<Map<string, string>> {
    const mentorId: string = app.currentUser?.customData.mentor;
    const menteeId: string = app.currentUser?.customData.mentee;

    const idsToFetch: string[] = [mentorId, menteeId].filter((uid) =>
      Boolean(uid)
    );
    const foundUsers: string[] = await db
      .collection("User")
      .find({ _id: { $in: idsToFetch } }, { projection: fieldsToFetch });

    const foundUsersMap: Map<string, string> = new Map();
    for (const user of foundUsers) {
      if (user["_id"] == mentorId) {
        foundUsersMap.set("mentor", user);
      }
      if (user["_id"] === menteeId) {
        foundUsersMap.set("mentee", user);
      }
    }
    return foundUsersMap;
  }

  const wrapped = {
    app: app,
    LogIn,
    LogInAnonymous,
    LogOut,
    Register,
    appRefreshCustomData,
    setMongoUserAttribute,
    maybeSetMongoUserBoolean,
    fetchCircle,
    fetchMentorMentee,
    appCustomData,
    appCurrentUser,
    appUsers,
    db,
  };

  return (
    <RealmAppContext.Provider value={wrapped}>
      {children}
    </RealmAppContext.Provider>
  );
};

export const useRealmApp = () => {
  const app = React.useContext(RealmAppContext);
  if (!app) {
    throw new Error(
      `You must call useRealmApp() inside of a <RealmAppProvider />`
    );
  }
  return app;
};

export function tryRedirect(path, onboarding = false) {
  var full_path = path;
  if (onboarding) {
    full_path = full_path + "?state=onboarding";
  }
  if (typeof window !== `undefined`) {
    console.log(`navigating to ${full_path}`);
    navigate(full_path);
  } else {
    console.log(`can't navigate to ${full_path} because no window`);
  }
  return null;
}
const signedOutPaths = [
  "",
  "/",
  "/member/login",
  "/member/signup",
  "/member/signup/email_confirmed",
  "/member/signup/confirm_email",
  "/member/reset_password",
  "/member/forgot_password",
];
const joinCirclePath = "/member/signup/join_circle";
const profilePicturePath = "/member/signup/profile_picture";
const otherDescrPath = "/member/signup/other_descr";
const selfDescrPath = "/member/signup/self_descr";

export const OnboardingRedirector = ({ location, children }) => {
  const trimmedPath =
    location?.pathname.slice(-1) == "/"
      ? location?.pathname.slice(0, -1)
      : location?.pathname;
  // const rand = Math.round(Math.random() * 1000);
  // console.log(`top-level path is ${location?.pathname} ${rand}`);
  const realmApp = useRealmApp();
  React.useEffect(() => {
    if (realmApp.app.currentUser) {
      // console.log(`refreshing custom data ${rand}`);
      realmApp.appRefreshCustomData();
    }
  }, []);

  const conditioned_onboarding_paths = [
    [
      !realmApp.appCurrentUser?.customData.hasProfilePicture,
      profilePicturePath,
    ],
    [
      !realmApp.appCurrentUser?.customData.mentorship_other_descr,
      otherDescrPath,
    ],
    [!realmApp.appCurrentUser?.customData.mentorship_self_descr, selfDescrPath],
  ];
  // all paths associated with onboarding
  var onboarding_paths = conditioned_onboarding_paths.map((p) => p[1]);
  // onboarding paths still need to be completed
  var needed_onboarding_paths = conditioned_onboarding_paths
    .filter((p) => p[0])
    .map((p) => p[1]);
  // currently visiting an onboarding path as part of onboarding
  const is_onboarding =
    new URL(
      location.href ? location.href : "http://place.holder"
    ).searchParams.get("state") == "onboarding" &&
    onboarding_paths.includes(trimmedPath);
  // console.log(`is_onboarding is ${is_onboarding} at '${trimmedPath}' ${rand}`);

  // console.log(
  //   `user email is ${realmApp.appCurrentUser?.customData.name}, user circle is ${realmApp.appCurrentUser?.customData.circle}`
  // );

  if (!realmApp.appCurrentUser) {
    // no logged in user
    if (!signedOutPaths.includes(trimmedPath)) {
      // console.log(
      //   `signedOutPaths of ${JSON.stringify(
      //     signedOutPaths
      //   )} doesn't include '${trimmedPath}'`
      // );
      return tryRedirect("/");
    } else {
      return <>{children}</>;
    }
  } else if (!realmApp.appCurrentUser?.customData.circle) {
    // console.log(`no circle yet at ${trimmedPath}, ${location.href} ${rand}`);
    // console.log(`customData is ${JSON.stringify(realmApp.appCurrentUser?.customData)}`)
    if (trimmedPath != joinCirclePath) {
      const invite_code = getUrlParam("invite_code");
      const path =
        invite_code != ""
          ? joinCirclePath + "?invite_code=" + invite_code
          : joinCirclePath;
      return tryRedirect(path);
    } else {
      return <>{children}</>;
    }
  } else if (trimmedPath == joinCirclePath) {
    // already joined circle, should not still be able to access this page
    return tryRedirect("/member/dashboard");
  } else if (needed_onboarding_paths.length > 0) {
    // needs onboarding, but isn't onboarding.
    if (!is_onboarding) {
      return tryRedirect(needed_onboarding_paths[0], true);
    } else {
      return <>{children}</>;
    }
  } else if (is_onboarding) {
    // is onboarding, but onboarding is not needed. Go to dashboard.
    return tryRedirect("/member/dashboard");
  } else if (realmApp.appCurrentUser && signedOutPaths.includes(trimmedPath)) {
    // there is a user, but they're in a logged-out path
    return tryRedirect("/member/dashboard");
  } else {
    return <>{children}</>;
  }
};

export const LogOutButton = () => {
  const realmApp = useRealmApp();
  async function handleLogout() {
    await realmApp.LogOut();
  }
  return (
    <Button
      className="light-gray-button logout-button"
      onClick={() => handleLogout()}
    >
      Log Out
    </Button>
  );
};
