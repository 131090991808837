import moment from "moment";

export const MILLISECONDS_PER_DAY: number = 86400000; // 24 * 60 * 60 * 1000
export const DAYS_IN_WEEK: number = 7;
export const MILLISECONDS_PER_WEEK = MILLISECONDS_PER_DAY * DAYS_IN_WEEK;

export const getNextMeetingTime = (
  frequencyInDays: number,
  startDateInUTCMilliseconds: number,
  adjustHours?: number
): number => {
  let now: Date = new Date();
  // Adjust the current time by adjustHours
  if (adjustHours) now.setHours(now.getHours() + adjustHours);
  const msNow: number = now.getTime();
  const msInterval: number = frequencyInDays * MILLISECONDS_PER_DAY;
  if (startDateInUTCMilliseconds > msNow) return startDateInUTCMilliseconds;
  let nextMtgTime: number = startDateInUTCMilliseconds;
  while (nextMtgTime < msNow) {
    nextMtgTime = nextMtgTime + msInterval;
  }
  return nextMtgTime;
};

export const validateEmail = (email: string) => {
  if (email.length > 1000) throw "Email too long.";
  if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
    throw "Please enter a valid email.";
  }
};

export const validatePassword = (password: string) => {
  if (password.length > 1000) {
    throw "Password too long. Please keep under 1000 characters.";
  }
  if (password.length < 5) {
    throw "Please enter password at least 5 characters long.";
  }
};
/**
 * Default validation used for react-phone-input-2's PhoneInput component.
 *
 * NOTE: The react-phone-input-2 library does not give more general types, so
 * we keep the use of {object} despite candidate property access lint errors.
 */
export const defaultPhoneInputValidation = (
  inputNumber: string,
  country: object,
  countries?: object[]
): boolean => {
  let noDetectedIssues: boolean = true;
  if (inputNumber.length < 5) {
    return false;
  }

  if (countries) {
    noDetectedIssues = countries.some((candidate) => {
      return (
        inputNumber.startsWith(candidate.dialCode) &&
        inputNumber.length > country.dialCode.length
      );
    });
  }

  if (country.iso2 === "us" || country.countryCode === "us") {
    try {
      validateUsaPhoneNumber(inputNumber);
    } catch {
      noDetectedIssues = false;
    }
  } else if (inputNumber.length <= country.dialCode.length) {
    noDetectedIssues = false;
  }

  return noDetectedIssues;
};

export const validateUsaPhoneNumber = (phone: string) => {
  /* Only used for US numbers. UI input does basic validation already. */
  var re = /1\d{10}/;
  if (!re.test(phone)) {
    throw (
      "Your phone number is invalid. US numbers should have country " +
      "code +1, followed by a 10 digit phone number. Instead, found: " +
      phone
    );
  }
};

export const validateName = (name: string) => {
  if (name.length == 0) {
    throw "Please a name so your group knows who you are!";
  }
  if (name.length > 100) throw "The name is too long.";
  var allowedChars = "abcdefghijklmnopqrstuvwxyz";
  allowedChars = allowedChars + allowedChars.toUpperCase() + "-. ";
  for (const c of name) {
    if (!allowedChars.includes(c)) {
      throw (
        "Your name contains invalid characters. Only letters, " +
        "periods, dashes, and spaces are allowed."
      );
    }
  }

  //If the first letter of the users name is not capitalized, throw an alert that it should be capitalized
  if (name.charAt(0) !== name.charAt(0).toUpperCase()) {
    throw "Please make sure name is capitalized";
  }

  //If there's no space in the middle, then throw error to add a last name
  if (name.indexOf(" ") == -1) {
    throw "Please use both first and last name";
  }
};

export const getUrlParam = (param) => {
  var fetched_param = "";
  if (typeof window !== "undefined") {
    const params = new URL(location.href).searchParams;
    fetched_param = params.get(param);
    fetched_param = fetched_param == null ? "" : fetched_param;
  }
  return fetched_param;
};

function capitalize(string) {
  const words = string.split(" ");
  for (let i = 0; i < words.length; i++) {
    words[i] = words[i][0].toUpperCase() + words[i].substr(1);
  }
  return words.join(" ");
}

export const getRelationTitle = (relation, capitalized = false) => {
  let mentor_term = "champion";
  let mentee_term = "driver";

  let title;

  if (relation == "mentor") {
    title = mentor_term;
  } else if (relation == "mentee") {
    title = mentee_term;
  } else {
    throw `getRelationTitle invalid input relation: ${relation}`;
  }

  if (capitalized) {
    title = capitalize(title);
  }
  return title;
};

export const getCircleStartTime = (circle_doc) => {
  if (circle_doc && circle_doc.kickoff_date) {
    let timezone = circle_doc.timezone
      ? circle_doc.timezone
      : "America/Los_Angeles";
    return moment(circle_doc.kickoff_date, timezone).unix() * 1000;
  }
  return null;
};
